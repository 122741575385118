import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TagResult } from '../../types';
import FeedbackItem from './FeedbackItem';

interface FeedbackListProps {
  feedbacks: TagResult[];
  codebookLanguage?: 'Hebrew' | 'English';
  translationEnabled?: boolean;
}

const checkHebrewPercentage = (feedbacks: string[]): boolean => {
  const hebrewRegex = /[\u0590-\u05FF]/;
  let totalChars = 0;
  let hebrewChars = 0;

  feedbacks.forEach((feedback) => {
    totalChars += feedback.length;
    for (let char of feedback) {
      if (hebrewRegex.test(char)) {
        hebrewChars++;
      }
    }
  });

  const hebrewPercentage = (hebrewChars / totalChars) * 100;
  return hebrewPercentage > 50;
};

const FeedbackList: React.FC<FeedbackListProps> = ({
  feedbacks,
  codebookLanguage = 'English',
  translationEnabled,
}) => {
  const [isHebrew, setIsHebrew] = useState(false);

  useEffect(() => {
    setIsHebrew(checkHebrewPercentage(feedbacks.map((f) => f.text)));
  }, [feedbacks]);

  return (
    <ListContainer>
      {feedbacks.map((feedback, index) => (
        <FeedbackItem
          shouldAlignRight={isHebrew}
          codebookLanguage={codebookLanguage}
          key={feedback.id || index}
          tagResult={feedback}
          translationEnabled={translationEnabled}
        />
      ))}
    </ListContainer>
  );
};

export default FeedbackList;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
