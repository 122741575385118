export enum Colors {
  // PRIMARY = '#A435F0',
  // PRIMARY = 'rgb(107,87,199)',
  // PRIMARY = '#C6B9FF',
  PRIMARY = '#6B57C6',
  ERROR = '#FF5959',
  PRIMARY_SOFT = '#ceafe3',
  PRIMARY_DISABLED = 'rgba(107,87,199, 0.4)',
  PRIMARY_02_OPACITY = '#A435F033',
  SECONDARY = '#ECEB98',
  CATEGORYֹֹֹ_BACKGROUND = '#e0e09b',
  CATEGORYֹֹֹ_SELECTED_BACKGROUND = '#a0cff9',
  GRAY_10 = '#F7F9FA',
  GRAY_20 = '#e1e1e1',
  GRAY_40 = '#99A0A6',
  GRAY_60 = '#6A6F73',
  GRAY_100 = '#1C1D1F',
  GRAY_100_02_OPACITY = '#1C1D1F33',
  PURPLE_10 = '#FAF2FF',
  PURPLE_20 = '#F0DAFF',
  PURPLE_30 = '#E4BBFF',
  WHITE = '#FFFFFF',
  BORDER = ' #D8D1DC',
  BORDER_WITH_OPACITY = ' #D8D1DC99',
  NEW_BORDER = '#c7c7c7',
  TEXT_BLACK_05_OPACITY = '#1C1D1F80',
  TEXT_GRAY = '#818181',
  WHITE_BACKGROUND = '#F5F7FC',
  WHITE_BACKGROUND2 = '#E1E8F6',

  BACKGROUND = '#d6d8e0',
  LINK_BLUE = '#0000EE',

  // new pallete

  B10 = '#F7F7F7',
  B20 = '#DDDDDD',
  B30 = '#B2B2B2',
  B40 = '#999',
  B50 = '#808080',
  B60 = '#666',
  B70 = '#4D4D4D',
  B80 = '#333',
  B90 = '#1A1A1A',
  B100 = ' #000',
  P5 = '#F9F7FF',
  P10 = '#F0EEF9',
  P20 = '#E1DDF4',
  P30 = '#D3CDEE',
  P40 = '#D1C7FF',
  P50 = '#B5ABE3',
  P60 = '#A69ADD',
  P70 = '#9789D7',
  P80 = '#8979D1',
  P90 = '#7A68CC',
  P100 = ' #6B57C6',
}
