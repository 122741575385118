import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import InfoIcon from '../icons/InfoIcon';
import H3 from '../newTextComponents/H3';
import P2 from '../newTextComponents/P2';

const DataFileSetupCard: React.FC = () => {
  return (
    <Container>
      <TopContainer>
        <StyledInfoIcon color={Colors.P100} />
        <TopHeader>How to Structure Your Data File:</TopHeader>
      </TopContainer>
      <List>
        <li>
          <Header>Top Row:</Header>{' '}
          <P2>
            Start with your variable names or survey questions—no blank rows up
            here, please!
          </P2>
        </li>
        <li>
          <Header>Data Rows:</Header>{' '}
          <P2>
            Add your data below, including any demographics, closed-ended
            responses, and text for analysis. Empty rows within the data are
            fine!
          </P2>
        </li>
        <li>
          <Header>Excel Files:</Header>{' '}
          <P2>Make sure the data we’ll analyze is on the first sheet.</P2>
        </li>
      </List>
      <P2>
        Want to see the exact format? Download the example file for guidance!
      </P2>
    </Container>
  );
};

export default DataFileSetupCard;

const Container = styled.div`
  padding: 4px;
  border-radius: 8px;
  max-width: 550px;
  width: 100%;
`;

const TopContainer = styled(CenteredHorizontalFlex)`
  gap: 12px;
`;

const List = styled.ul`
  padding-left: 20px;
  margin-bottom: 16px;
`;

const TopHeader = styled(H3)`
  color: ${Colors.P100};
`;

const Header = styled(H3)`
  font-size: 14px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: ${Colors.P10};
  border-radius: 18px;
`;
