import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { TagResult } from '../../types';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import HorizontalFlex from '../containers/HorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import P1 from '../newTextComponents/P1';
import P3 from '../newTextComponents/P3';

interface FeedbackItemProps {
  tagResult: TagResult;
  shouldAlignRight?: boolean;
  codebookLanguage: 'Hebrew' | 'English';
  translationEnabled?: boolean;
}

const FeedbackItem: React.FC<FeedbackItemProps> = ({
  tagResult,
  shouldAlignRight,
  codebookLanguage,
  translationEnabled,
}) => {
  const [showTranslation, setShowTranslation] = useState(!!translationEnabled);
  const hasTranslation = !!tagResult.translatedText;

  useEffect(() => {
    setShowTranslation(!!translationEnabled);
  }, [translationEnabled]);

  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };

  const displayText =
    showTranslation && tagResult.translatedText
      ? tagResult.translatedText
      : tagResult.text;

  const sourceLanguage = tagResult.sourceLanguageISOCode;

  return (
    <ItemContainer>
      <ContentContainer>
        <FeedbackText $shouldAlignRight={shouldAlignRight}>
          {displayText}
        </FeedbackText>
        {translationEnabled && hasTranslation && sourceLanguage && (
          <LanguageISOCodeButton
            onClick={toggleTranslation}
            // label={sourceLanguage || ''}
            // style={{ padding: '4px 16px' }}
            $showTranslation={showTranslation}
          >
            <SourceLanguageText $showTranslation={showTranslation}>
              {sourceLanguage}
            </SourceLanguageText>
          </LanguageISOCodeButton>
        )}
      </ContentContainer>
      <TagsContainer $shouldAlignRight={codebookLanguage === 'Hebrew'}>
        {tagResult.themes.map((theme, index) => (
          <Tag key={index}>{theme}</Tag>
        ))}
      </TagsContainer>
    </ItemContainer>
  );
};

const ItemContainer = styled(VerticalFlex)`
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  gap: 12px;
  position: relative;
`;

const ContentContainer = styled(HorizontalFlex)`
  justify-content: space-between;
`;

const SourceLanguageText = styled(P3)<{
  $showTranslation: boolean;
}>`
  font-size: 11px;
  line-height: 12px;
  ${({ $showTranslation }) =>
    $showTranslation ? `color: ${Colors.B40};` : `color: ${Colors.P70};`}
`;

const LanguageISOCodeButton = styled.button<{
  $showTranslation: boolean;
}>`
  background-color: transparent;
  outline: none;
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    border-color: ${Colors.P40};
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px ${Colors.B20} solid;
  border-radius: 10px;
  padding: 0;
  width: 21px;
  min-width: 21px;
  height: 21px;
  ${({ $showTranslation }) =>
    $showTranslation
      ? `background-color: ${Colors.WHITE};`
      : `background-color: ${Colors.P5}; border: 1px ${Colors.P40} solid;`}
`;

const FeedbackText = styled(P1)<{ $shouldAlignRight?: boolean }>`
  ${({ $shouldAlignRight }) =>
    $shouldAlignRight ? 'text-align: right; direction: rtl;' : ''}
`;

const Tag = styled(P3)`
  padding: 4px 16px;
  background-color: ${Colors.P40};
  border-radius: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TagsContainer = styled(CenteredHorizontalFlex)<{
  $shouldAlignRight: boolean;
}>`
  flex-wrap: wrap;
  gap: 8px;
  direction: ${({ $shouldAlignRight }) => ($shouldAlignRight ? 'rtl' : 'ltr')};
`;

export default FeedbackItem;
