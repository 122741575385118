import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { TagResult, Theme } from '../../types';
import logger from '../../utils/logger';
import { areThemeIdsEqual } from '../../utils/theme-utils';
import FilterButtonWithActive from '../buttons/FilterButtonWithActive';
import ReloadButtonWithActive from '../buttons/ReloadButtonWithActive';
import Tooltip from '../buttons/Tooltip';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import HorizontalFlex from '../containers/HorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import AutoCompleteSelectFilter from '../form/select/AutoCompleteSelectFilter';
import AutoCompleteSelectTag from '../form/select/AutoCompleteSelectTag';
import FilterIcon from '../icons/FilterIcon';
import ReloadIcon from '../icons/ReloadIcon';
import H3 from '../newTextComponents/H3';
import P2 from '../newTextComponents/P2';
import FeedbackList from './FeedbackList';
import TagResultsLoader from './TagResultsLoader';

interface TagResultsWithScrollToEndProps {
  results?: TagResult[];
  potentialFilters: Theme[];
  nonEmptyUserContentCount?: number;
  onScrollToEnd: VoidFunction;
  isFetchingNextPage?: boolean;
  pageIsLoading?: boolean;
  codebookIsDirty: boolean;
  createTheme: (themeName: string, includeExample: string) => void;
  addSelectedTextToTheme: (selectedText: string, themeToTagBy: Theme) => void;
  onRefreshTagResultsClick: VoidFunction;
  codebookLanguage: 'Hebrew' | 'English';
  isCodebookInFirstVersion: boolean;
  filters: Theme[];
  setFilters: React.Dispatch<React.SetStateAction<Theme[]>>;
  translationEnabled?: boolean;
}

const TagResultsWithScrollToEnd: React.FC<
  PropsWithChildren<TagResultsWithScrollToEndProps>
> = ({
  results,
  potentialFilters,
  nonEmptyUserContentCount,
  onScrollToEnd,
  isFetchingNextPage,
  pageIsLoading,
  codebookIsDirty,
  createTheme,
  addSelectedTextToTheme,
  onRefreshTagResultsClick,
  codebookLanguage = 'English',
  isCodebookInFirstVersion,
  filters,
  setFilters,
  translationEnabled,
}) => {
  // Other id is used for comparison
  const filtersWithOther = [{ name: 'Other', id: 9991 }, ...potentialFilters];
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const listContentsRef = useRef<HTMLDivElement>(null);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filterIconBoundingRect, setFilterIconBoundingRect] = useState<
    DOMRect | undefined
  >();

  const filterIconRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (filterIconRef?.current) {
      setFilterIconBoundingRect(filterIconRef.current.getBoundingClientRect());
    }
  }, [filterIconRef?.current]);

  const [selectedText, setSelectedText] = useState<string | undefined>();

  const handleScroll = useCallback(() => {
    const listContainer = scrollContainerRef.current;
    if (listContainer) {
      const isAtBottom =
        listContainer.scrollTop + listContainer.clientHeight >
        listContainer.scrollHeight - 4500;
      // listContainer.scrollHeight - 300;
      if (isAtBottom) {
        // Call your callback function here
        if (!isFetchingNextPage) {
          onScrollToEnd();
        }
      }
    }
  }, [isFetchingNextPage, onScrollToEnd]);

  useEffect(() => {
    handleScroll();
  }, [filters, handleScroll]);

  useEffect(() => {
    const listContainer = scrollContainerRef.current;
    if (listContainer) {
      listContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listContainer) {
        listContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollContainerRef.current, handleScroll]);

  const [selectedTextBoundingRect, setSelectedTextBoundingRect] = useState<
    Pick<DOMRect, 'top' | 'left'> | undefined
  >();

  const calculatePosition = (position: DOMRect) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Assuming the component width is 400px and height is 300px
    // You might want to measure this dynamically for more accuracy
    const componentWidth = 440;
    const componentHeight = 370;

    let top = position.y + 60;
    let left = position.x + 100;

    // Check right overflow
    if (left + componentWidth > viewportWidth) {
      left = position.x - componentWidth;
    }

    // Check bottom overflow
    if (top + componentHeight > viewportHeight) {
      top = position.y - componentHeight - 40; // 10px offset
    }

    setSelectedTextBoundingRect({ top, left });
  };

  const deferredHandleTextSelect = () => {
    logger.info('deferredHandleTextSelect');
    const selectionElement = window.getSelection();

    if (!selectionElement) {
      return;
    }

    const selectionBoundingRect =
      selectionElement.focusNode?.parentElement?.getBoundingClientRect();
    const selectedText = selectionElement.toString();
    if (!selectionBoundingRect || !selectedText || selectedText.length < 2) {
      return;
    }
    setSelectedText(selectedText);
    calculatePosition(selectionBoundingRect);
  };

  const handleTextSelect = () => {
    logger.info('handleTextSelect');
    setTimeout(deferredHandleTextSelect, 0);
  };

  const textSelectionStarted = () => {
    logger.info('textSelectionStarted ');
    setSelectedTextBoundingRect(undefined);
    setSelectedText(undefined);
    document.addEventListener('mouseup', handleTextSelect, { once: true });
  };

  const updateTagsButtonDisabled = pageIsLoading || !codebookIsDirty;
  const filterTagsButtonDisabled = pageIsLoading;
  const tagSampleCounter =
    nonEmptyUserContentCount && results
      ? `( ${results.length} / ${nonEmptyUserContentCount} )`
      : '';

  return (
    <Container>
      <Content>
        <TitleContainer>
          <H3>Tagged Survey Responses</H3>
          <P2>{tagSampleCounter}</P2>
        </TitleContainer>
        <Buttons>
          <Tooltip toolTipText='Filter by theme' disabled={true} shiftLeft>
            <div ref={filterIconRef} />
            <FilterButtonWithActive
              icon={<StyledFilterIcon />}
              onClick={() => setShowFilterMenu((a) => !a)}
              disabled={filterTagsButtonDisabled}
            />
          </Tooltip>
          <Tooltip
            toolTipText='Refresh to apply latest changes'
            disabled={updateTagsButtonDisabled}
            shiftLeft
          >
            <ReloadButtonWithActive
              icon={<StyledReloadIcon />}
              onClick={onRefreshTagResultsClick}
              disabled={updateTagsButtonDisabled}
            />
          </Tooltip>
        </Buttons>
      </Content>
      {pageIsLoading ? (
        <TagResultsLoader isFirstLoad={isCodebookInFirstVersion} />
      ) : (
        <ContentsContainer
          ref={scrollContainerRef}
          onMouseDown={textSelectionStarted}
        >
          {results && (
            <div ref={listContentsRef}>
              <FeedbackList
                feedbacks={results}
                codebookLanguage={codebookLanguage}
                translationEnabled={translationEnabled}
              />
            </div>
          )}
          {isFetchingNextPage ? <TagResultsLoader isFetchingNextPage /> : null}
        </ContentsContainer>
      )}
      {selectedTextBoundingRect && selectedText && (
        <AutoCompleteSelectTag
          themes={potentialFilters}
          placeholder='Enter tag name...'
          label={`tag - ${selectedText}`}
          disabled={false}
          position={selectedTextBoundingRect}
          onSelectTheme={(theme) => {
            logger.info('onSelectTheme: ', theme);
            addSelectedTextToTheme(selectedText, theme);
            setSelectedTextBoundingRect(undefined);
          }}
          onInputKeyDown={(inputValue) => {
            logger.info('onInputKeyDown: ', inputValue);
            createTheme(inputValue, selectedText);
            setSelectedTextBoundingRect(undefined);
          }}
          close={() => {
            logger.info('close AutoCompleteSelect ');
            setSelectedTextBoundingRect(undefined);
          }}
        />
      )}
      {showFilterMenu && filterIconBoundingRect && (
        <AutoCompleteSelectFilter
          potentialFilters={filtersWithOther}
          activeFilters={filters}
          placeholder='Search for theme'
          label='Filter responses by'
          disabled={false}
          position={filterIconBoundingRect}
          toggleFilter={(filter) => {
            setFilters((prev) => {
              if (prev.some((f) => areThemeIdsEqual(f, filter))) {
                return prev.filter((t) => !areThemeIdsEqual(t, filter));
              } else {
                return [...prev, filter];
              }
            });

            logger.info('Filter onSelectFilter: ', filter);
          }}
          onInputKeyDown={(inputValue) => {
            logger.info('Filter onInputKeyDown: ', inputValue);
          }}
          close={() => setShowFilterMenu(false)}
        />
      )}
    </Container>
  );
};

export default TagResultsWithScrollToEnd;

const Container = styled(VerticalFlex)`
  border-radius: 16px;
  background: ${Colors.B10};
  padding: 16px 0;
  max-width: 669px;
`;

const TitleContainer = styled(HorizontalFlex)`
  gap: 10px;
`;

const Content = styled(CenteredHorizontalFlex)`
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 16px;
`;
const Buttons = styled(CenteredHorizontalFlex)`
  gap: 12px;
`;

const ContentsContainer = styled(VerticalFlex)`
  overflow-y: scroll;
  max-height: calc(100vh - 288px);
  padding: 6px 22px;
`;

const CommonButtonStlye = `
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

const StyledReloadIcon = styled(ReloadIcon)`
  ${CommonButtonStlye};
`;

const StyledFilterIcon = styled(FilterIcon)`
  ${CommonButtonStlye};
`;
