import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import Papa from 'papaparse';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  createOrUpdateJobConfigurationApi,
  fetchProjectApi,
  fetchThemesApi,
  getJobConfigurationApi,
  getJobVersionApi,
  getNonEmptyUserContentCount,
  multiTagDataApi,
  reshuffleTagSampleOrderApi,
  saveThemesApi,
  tagPageApi,
} from '../api/pipelineApi';
import DownloadIcon from '../assets/download.svg';
import MagicWandIcon from '../assets/magic-wand.svg';
import ThreeDotsMenuIcon from '../assets/three_dots_menu.svg';
import TranslateIcon from '../assets/translate.svg';
import PageHeader from '../components/PageHeader';
import Separator from '../components/Seperator';
import ToggleInputWithLabel from '../components/ToggleInputWithLabel';
import RestoreVersionMenu from '../components/business-flows/restore-version/RestoreVersionMenu';
import PrimaryButton from '../components/buttons/PrimaryButton';
import StyledButtonWithActive from '../components/buttons/StyledButtonWithActive';
import CodebookAdvancedSettingsModal, {
  OnCloseFunctionTypeParams,
} from '../components/codebookEditor/CodebookAdvancedSettingsModal';
import CodebookControlBar from '../components/codebookEditor/CodebookControlBar';
import CenteredVerticalFlex from '../components/containers/CenteredVerticalFlex';
import HorizontalFlex from '../components/containers/HorizontalFlex';
import VerticalFlex from '../components/containers/VerticalFlex';
import BackIcon from '../components/icons/BackIcon';
import Menu from '../components/menu/Menu';
import MenuIcon from '../components/menu/MenuIcon';
import MenuButton from '../components/menu/MenuItemTextButtonWithIcon';
import ErrorModal from '../components/modal/ErrorModal';
import MessageModal from '../components/modal/MessageModal';
import H1 from '../components/newTextComponents/H1';
import P1 from '../components/newTextComponents/P1';
import LoadThemesModal from '../components/researchForm/LoadThemesModal';
import LoadingModal from '../components/tagResults/LoadingModal';
import TagResultsWithScrollToEnd from '../components/tagResults/TagResultsWithScrollToEnd';
import ThemesList from '../components/themes/ThemesList';
import useIsRestoreVersionEnabled from '../hooks/useIsRestoreVersionEnabled';
import useOutsideClickHandler from '../hooks/useOutsideClickHandler';
import useTranslation from '../hooks/useTranslation';
import { useFormContext } from '../state/useFormContext';
import { Colors } from '../theme/Colors';
import { JobVersionData, JobVersionStatus, TagResult, Theme } from '../types';
import { GENERAL_ERROR_MESSAGE } from '../utils/constants';
import { downloadThemesFileUtil } from '../utils/csv-utils';
import logger from '../utils/logger';
import { areThemeIdsEqual, compareThemeLists } from '../utils/theme-utils';

interface SplitViewCodebookEditorPageProps {}

const SplitViewCodebookEditorPage: React.FC<
  SplitViewCodebookEditorPageProps
> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [tagSampleCounter, setTagSampleCounter] = useState(
    {} as { [themeName: string]: number }
  );
  const { projectId, jobId, jobVersionId } = useParams();
  if (!projectId || !jobId || !jobVersionId) {
    throw new Error('Missing URL parameters');
  }

  const [missingParamsError, setMissingParamsError] = useState('');

  useEffect(() => {
    if (
      !parseInt(projectId!) ||
      !parseInt(jobId!) ||
      !parseInt(jobVersionId!)
    ) {
      setMissingParamsError('Partial or missing URL parameters');
    }
  }, [jobVersionId, jobId, projectId]);

  const [showLoadThemesModal, setShowLoadThemesModal] = useState(false);
  const [withRandomSampleOrder, setWithRandomSampleOrder] = useState(true);
  const [submitError, setSubmitError] = useState({
    title: '',
    subTitle: '',
  });
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showTagAllLoaderModal, setShowTagAllLoaderModal] = useState(false);

  const [showFetchThemesErrorModal, setShowFetchThemesErrorModal] =
    useState(false);
  const [themesDraft, setThemesDraft] = useState<Theme[]>([]);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [codebookIsDirty, setCodebookIsDirty] = useState(false);
  const [isCodebookInFirstVersion, setIsCodebookInFirstVersion] =
    useState(true);

  const [filters, setFilters] = useState<Theme[]>([]);
  const [filteredData, setFilteredData] = useState([] as TagResult[]);
  useEffect(() => {
    const equal = compareThemeLists(themes, themesDraft);
    setCodebookIsDirty(!equal);
  }, [themes, themesDraft, setCodebookIsDirty]);

  const codebookContainerRef = useRef<HTMLDivElement>(null);

  const { data: jobVersionData } = useQuery<JobVersionData, Error>({
    queryKey: ['jobVersion', jobVersionId],
    queryFn: () => getJobVersionApi(jobVersionId!),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!jobVersionData) return;

    if (
      [
        JobVersionStatus.PROCESSED,
        JobVersionStatus.PROCESSING,
        JobVersionStatus.FAILED,
      ].includes(jobVersionData.status)
    ) {
      logger.info(
        `jobVersionData.status: ${jobVersionData.status}, navigating to /projects/${projectId}`
      );
      navigate(`/projects/${projectId}`);
    }
    if (jobVersionData.status === JobVersionStatus.ANALYZED) {
      logger.error(
        `unexpected scenario: jobVersionData.status: ${jobVersionData.status}, navigating to /projects/${projectId}`
      );
      navigate(`/projects/${projectId}`);
    }
  }, [jobVersionData, navigate, projectId]);

  const {
    mutateAsync: createOrUpdateConfigMutateAsync,
    isPending: createOrUpdateConfigIsLoading,
    error: createOrUpdateConfigError,
    reset: createOrUpdateConfigReset,
  } = useMutation<any, Error, object>({
    mutationFn: (data) =>
      createOrUpdateJobConfigurationApi(data, jobVersionId!),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['jobConfiguration', jobVersionId],
      });
    },
  });

  const {
    mutateAsync: saveThemesMutateAsync,
    isPending: saveThemesIsLoading,
    error: saveThemesError,
    reset: saveThemesReset,
  } = useMutation<any, Error, Theme[]>({
    mutationFn: (themes) => saveThemesApi(themes, jobVersionId!),
    onSuccess: (data) => {
      logger.info('saveThemesMutateAsync: onSuccess data:', data);
      queryClient.invalidateQueries({
        queryKey: ['themes', jobVersionId],
      });
    },
  });

  const {
    data: fetchThemesData,
    isFetching: fetchThemesLoading,
    error: fetchThemesError,
  } = useQuery<any, Error>({
    queryKey: ['themes', jobVersionId],
    queryFn: () => fetchThemesApi(jobVersionId!),
    retry: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (fetchThemesData) {
      setThemesDraft(fetchThemesData);
      setThemes(fetchThemesData);
    }
  }, [fetchThemesData]);

  const {
    data: tagPageData,
    error: tagPageError,
    fetchNextPage,
    hasNextPage,
    isFetching: tagPageIsFetching,
    isLoading: tagPageIsLoading,
    isFetchingNextPage,
    refetch: tagPageRefetch,
  } = useInfiniteQuery({
    queryKey: ['tag_page', jobVersionId, withRandomSampleOrder],
    queryFn: async ({ pageParam, signal }) => {
      logger.info('tag_page queryFn pageParam: ', pageParam);
      return tagPageApi(
        jobVersionId!,
        pageParam,
        withRandomSampleOrder,
        signal
      );
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    initialPageParam: 0,
    enabled: !!themes && themes.length > 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      const res = data.pages.reduce((acc, page) => [...acc, ...page.data], []);
      return res;
    },
  });

  useEffect(() => {
    if (!tagPageData) return;

    if (filters.length === 0) {
      setFilteredData(tagPageData as unknown as TagResult[]);
      return;
    }

    const filtered = (tagPageData as unknown as TagResult[]).filter((item) => {
      return filters.every((filter) =>
        item.themes.some((themeName) => themeName === filter.name)
      );
    });
    setFilteredData(filtered);
  }, [tagPageData, filters]);

  useEffect(() => {
    if (!tagPageData) return;

    const themeCounter = (tagPageData as TagResult[]).reduce(
      (counter, item) => {
        item.themes.forEach((theme) => {
          // @ts-ignore
          counter[theme] = (counter[theme] || 0) + 1;
        });
        return counter;
      },
      {}
    );

    setTagSampleCounter(themeCounter);
  }, [tagPageData]);

  useEffect(() => {
    if (fetchThemesError) {
      logger.error('fetchThemesError: ', fetchThemesError);
      setShowFetchThemesErrorModal(true);
    }
  }, [fetchThemesError]);

  const { data: projectData } = useQuery<any, Error>({
    queryKey: ['project', projectId],
    queryFn: () => fetchProjectApi(projectId!),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  const { data: jobConfigurationData } = useQuery<any, Error>({
    queryKey: ['jobConfiguration', jobVersionId],
    queryFn: () => getJobConfigurationApi(jobVersionId!),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: nonEmptyUserContentCountData } = useQuery<any, Error>({
    queryKey: ['nonEmptyUserContentCount', jobId],
    queryFn: () => getNonEmptyUserContentCount(jobId!),
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const isRestoreVersionEnabled = useIsRestoreVersionEnabled(
    jobId,
    jobVersionId
  );

  const {
    mutate: tagAllMutation,
    // isPending: tagAllIsLoading,
    error: tagAllError,
    reset: tagAllReset,
  } = useMutation<any, Error, Theme[]>({
    mutationFn: (data) => multiTagDataApi(data, jobVersionId!),
    onSuccess: () => {
      setTimeout(() => {
        setShowTagAllLoaderModal(false);
        navigate(`/projects/${projectId}`);
      }, 2000);
    },
    onError: (error) => {
      logger.error('error: ', error);
      setShowTagAllLoaderModal(false);
    },
  });

  const {
    toggleTranslate,
    presentTranslation,
    resetTranslationError,
    translateToggleChecked,
    translationIsLoading,
    translationError,
  } = useTranslation(jobVersionId, jobId);

  const { showUpdateAppVersionDialog, refreshAppVersion } = useFormContext();

  const onApproveUpdateAppVersion = async () => {
    await saveThemesMutateAsync(themesDraft);
    refreshAppVersion();
  };

  const removeTheme = (index: number, theme: Theme) => {
    setFilters((old) =>
      old.filter((t) => {
        if (theme.id !== undefined) {
          return t.id !== theme.id;
        }

        return t.localId !== theme.localId;
      })
    );

    setThemesDraft((old) => old.filter((_, i) => i !== index));
  };

  const [showMenu, setShowMenu] = useState(false);
  const menuIconRef = useRef<HTMLImageElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler([menuIconRef, menuRef], () => {
    setShowMenu(false);
  });

  const updateTheme = (index: number, update: Partial<Theme>) => {
    setThemesDraft((old) => {
      const newThemes = [...old];
      const oldTheme = newThemes[index];
      const merged = { ...oldTheme, ...update };
      newThemes[index] = merged;
      return newThemes;
    });
  };

  const addSelectedTextToTheme = (text: string, theme: Theme) => {
    setThemesDraft((old) => {
      const newThemes = old.map((t) => {
        if (areThemeIdsEqual(t, theme)) {
          const newTheme = { ...t };
          newTheme.instructions = t.instructions
            ? `${t.instructions}, ${text}`
            : `Include: ${text}`;

          return newTheme;
        }

        return t;
      });

      return newThemes;
    });
  };

  const addTheme = (themeName?: string, includeExample?: string) => {
    logger.info(
      `addTheme: themeName ${themeName} includeExample ${includeExample}`
    );
    setThemesDraft((old) => [
      ...old,
      {
        localId: Date.now(),
        name: themeName ?? '',
        instructions: includeExample ? `Include: ${includeExample}` : '',
      },
    ]);
  };

  const validateForm = () => {
    if (themesDraft.length <= 1) {
      setSubmitError({
        title: 'Codebook Required',
        subTitle:
          'It looks like a codebook is missing. Please load an existing codebook, or allow Blix to generate themes for you. Note that at least two themes are required to proceed',
      });
      return false;
    }

    const allThemesWithValidNames = themesDraft.every(
      (theme) => theme.name?.length > 0
    );
    if (!allThemesWithValidNames) {
      setSubmitError({
        title: 'Empty Theme Name Detected',
        subTitle:
          'It seems like one or more themes are unnamed. Please ensure all themes have valid names before proceeding',
      });
      return false;
    }

    return true;
  };

  const tagAll = () => {
    if (!validateForm()) {
      alert("Form isn't valid");
      return;
    }

    setShowTagAllLoaderModal(true);
    tagAllMutation(themesDraft);
  };

  const selectThemesFile = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      logger.info("couldn't find file");
      return;
    }

    Papa.parse(file, {
      header: true,
      complete: function (results) {
        const localThemes = results.data as Theme[];
        setThemesDraft((old) => [...old, ...localThemes]);
        setShowLoadThemesModal(false);
      },
    });
  }, []);

  const closeAdvancedSettingsModal = async (
    params: OnCloseFunctionTypeParams
  ) => {
    await createOrUpdateConfigMutateAsync(params);
    setShowSettingsModal(false);
  };

  const downloadThemesFile = async () => {
    logger.info('downloadThemesFile - started with themesDraft: ', themesDraft);
    const update = await saveThemesMutateAsync(themesDraft);
    downloadThemesFileUtil(
      update,
      `${projectData?.name || 'New_Research'}_codebook`
    );
  };

  const onScrollToEndOfTagResults = () => {
    if (hasNextPage && !tagPageIsFetching) {
      logger.info('onScrollToEndOfTagResults: calling fetchNextPage');
      fetchNextPage();
    }
  };

  const refreshCurrentPage = async () => {
    logger.info('refreshCurrentPage - started with themesDraft: ', themesDraft);
    await saveThemesMutateAsync(themesDraft);
    setIsCodebookInFirstVersion(false);
    await queryClient.resetQueries({
      queryKey: ['tag_page', jobVersionId],
    });
  };

  async function handleCheckboxChange(event: any) {
    logger.info('handleCheckboxChange - started');
    const checked = event.target.checked;
    setWithRandomSampleOrder(checked);
    if (checked) {
      await reshuffleTagSampleOrderApi(jobVersionId!);
    }

    await queryClient.resetQueries({
      queryKey: ['tag_page', jobVersionId],
    });
  }

  const navigateBack = async () => {
    await saveThemesMutateAsync(themesDraft);
    navigate(`/projects/${projectId}/${jobId}/${jobVersionId}/setup`);
  };

  const closeTagAllError = () => {
    tagAllReset();
    navigate(`/projects/${projectId}`);
  };

  return (
    <Container>
      <PageHeader />
      <ContentsContainer>
        <label style={{ width: 200, display: 'none' }}>
          With Random Order
          <input
            type='checkbox'
            onChange={handleCheckboxChange}
            defaultChecked={true}
          />
        </label>
        <PageHeadersContainer>
          <HorizontalFlex>
            <BackButtonWithActive
              icon={<StyledBackIcon />}
              onClick={navigateBack}
            />
            <VerticalFlex>
              <H1>Refine Your Codebook</H1>
              <SubTitle>
                Customize and refine your collection of themes by deleting,
                renaming, or adding new ones as needed.
              </SubTitle>
            </VerticalFlex>
          </HorizontalFlex>
          <PageHeaderRightContainer>
            <PrimaryButton
              // disabled
              label='Apply tags to all data'
              onClick={tagAll}
              icon={<img src={MagicWandIcon} />}
            />
            <StyledMenuIcon
              ref={menuIconRef}
              src={ThreeDotsMenuIcon}
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu((x) => !x);
              }}
              $clicked={showMenu}
            />
            {showMenu && (
              <StyledMenu $visible={showMenu} ref={menuRef}>
                <MenuButton
                  text='Download Codebook'
                  icon={<MenuItemIcon src={DownloadIcon} />}
                  onClick={downloadThemesFile}
                />
                {isRestoreVersionEnabled && (
                  <RestoreVersionMenu
                    projectId={projectId}
                    jobId={jobId}
                    activeJobVersionId={jobVersionId}
                    setShowMenu={setShowMenu}
                  />
                )}
                <Separator />
                <TranslateToggle
                  label='Translate Verbatim'
                  onChange={toggleTranslate}
                  checked={translateToggleChecked}
                  loading={translationIsLoading}
                  icon={<MenuItemIcon src={TranslateIcon} />}
                />
              </StyledMenu>
            )}
          </PageHeaderRightContainer>
        </PageHeadersContainer>
        <TopContainer>
          <CodebookContainer ref={codebookContainerRef}>
            <CodebookControlBar
              onClickDownloadCodebook={downloadThemesFile}
              onClickAddTheme={() => addTheme()}
              onClickSettings={() => setShowSettingsModal(true)}
            />
            <ThemesList
              tagSampleCounter={tagSampleCounter}
              themes={themesDraft}
              onRemoveTheme={removeTheme}
              onUpdateTheme={updateTheme}
              onAddTheme={addTheme}
              resultsCount={tagPageData?.length}
            />
          </CodebookContainer>
          <TagResultsContainer>
            <TagResultsWithScrollToEnd
              filters={filters}
              setFilters={setFilters}
              potentialFilters={themesDraft}
              results={filteredData}
              nonEmptyUserContentCount={nonEmptyUserContentCountData?.count}
              onScrollToEnd={onScrollToEndOfTagResults}
              isFetchingNextPage={isFetchingNextPage}
              pageIsLoading={tagPageIsLoading}
              codebookIsDirty={codebookIsDirty}
              createTheme={addTheme}
              addSelectedTextToTheme={addSelectedTextToTheme}
              onRefreshTagResultsClick={refreshCurrentPage}
              codebookLanguage={jobConfigurationData?.codebook_language}
              isCodebookInFirstVersion={isCodebookInFirstVersion}
              translationEnabled={presentTranslation}
            />
          </TagResultsContainer>
        </TopContainer>
        {showSettingsModal && (
          <CodebookAdvancedSettingsModal
            hidden={!showSettingsModal}
            onClose={closeAdvancedSettingsModal}
            existingTagExampleText={jobConfigurationData?.prompt_example_string}
            existingMaxTags={jobConfigurationData?.max_tags_per_row}
          />
        )}
        {showUpdateAppVersionDialog && (
          <MessageModal
            withCloseButton={false}
            show={showUpdateAppVersionDialog}
            approveButtonText='OK'
            onApprove={onApproveUpdateAppVersion}
            title='New Blix Version'
            subTitle='Great news! A new version of Blix is here! Click OK to update now – your work is saved.'
          />
        )}
        <LoadThemesModal
          hidden={!showLoadThemesModal}
          onClose={() => setShowLoadThemesModal(false)}
          onSelectFile={selectThemesFile}
        />
        <LoadingModal
          hidden={!showTagAllLoaderModal}
          title='Processing all data...'
        />
        <LoadingModal
          title='Saving your analysis config...'
          hidden={!createOrUpdateConfigIsLoading}
        />
        <LoadingModal
          hidden={!saveThemesIsLoading}
          title='Saving Codebook...'
        />
        <LoadingModal hidden={!fetchThemesLoading} title='Loading Themes...' />
        <ErrorModal
          show={!!tagAllError}
          onClose={closeTagAllError}
          title={'Tag All Error'}
          subTitle={tagAllError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!missingParamsError}
          onClose={() => {
            setMissingParamsError('');
            navigate(-1);
          }}
          title={'Missing Url Params'}
          subTitle={missingParamsError}
        />
        <ErrorModal
          show={!!translationError}
          onClose={resetTranslationError}
          title={'Translation Error'}
          subTitle={
            'There was an error with the translation. Please try again.'
          }
        />
        <ErrorModal
          show={!!createOrUpdateConfigError}
          onClose={createOrUpdateConfigReset}
          title={'Project Settings Error'}
          subTitle={createOrUpdateConfigError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={showFetchThemesErrorModal}
          onClose={() => setShowFetchThemesErrorModal(false)}
          title={'Fetching Themes Error'}
          subTitle={fetchThemesError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!saveThemesError}
          onClose={saveThemesReset}
          title={'Saving Codebook Error'}
          subTitle={saveThemesError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!tagPageError}
          onClose={tagPageRefetch}
          title={'Tag Sample Error'}
          subTitle={tagPageError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!submitError.title}
          onClose={() => setSubmitError({ title: '', subTitle: '' })}
          title={submitError.title}
          subTitle={submitError.subTitle}
        />
      </ContentsContainer>
    </Container>
  );
};

export default SplitViewCodebookEditorPage;

const Container = styled(CenteredVerticalFlex)`
  flex: 1;
`;

const TopContainer = styled(HorizontalFlex)`
  flex: 1;
  gap: 32px;
`;

const StyledMenuIcon = styled(MenuIcon)`
  position: unset;
`;

const StyledMenu = styled(Menu)`
  right: 0px;
  width: 262px;
`;

const ContentsContainer = styled(CenteredVerticalFlex)`
  flex: 1;
  width: 95%;
  max-width: calc(669px + 32px + 899px);
  margin-bottom: 24px;
  align-items: stretch;
  margin: 0 32px;
`;

const PageHeadersContainer = styled(HorizontalFlex)`
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0;
`;

const PageHeaderRightContainer = styled(HorizontalFlex)`
  position: relative;
  align-items: center;
  gap: 8px;
`;

const TranslateToggle = styled(ToggleInputWithLabel)`
  padding: 16px 24px;
`;

const CodebookContainer = styled(VerticalFlex)``;

const TagResultsContainer = styled(VerticalFlex)`
  flex-grow: 1;
  max-width: calc(100vw - 995px);
`;

const CommonButtonStlye = `
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

const StyledBackIcon = styled(BackIcon)`
  ${CommonButtonStlye};
`;

const BackButtonWithActive = styled(StyledButtonWithActive)`
  padding: 0 16px 16px 0;
  justify-content: flex-start;
  > svg:hover {
    background-color: ${Colors.P10};
  }
  > svg {
    border: 1px solid ${Colors.P100};
  }
  &:active > svg path {
    fill: ${Colors.WHITE};
  }
`;

const SubTitle = styled(P1)`
  margin-top: 8px;
  color: ${Colors.B60};
`;

const MenuItemIcon = styled.img`
  width: 20px;
  height: 20px;
`;
