import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';

const DropzoneContainer = styled(CenteredVerticalFlex)<{
  $isActive?: boolean;
  $withHover?: boolean;
}>`
  width: 620px;
  height: 320px;
  justify-content: center;
  cursor: pointer;
  border-radius: 12px;
  border: 1px dashed
    ${({ $isActive }) => ($isActive ? Colors.P100 : Colors.P50)};
  background-color: ${({ $isActive }) =>
    $isActive ? Colors.P5 : 'transparent'};
  ${({ $withHover }) =>
    $withHover &&
    `
  &:hover {
    border: 1px dashed ${Colors.P100};
    background-color: ${Colors.P5};
    }
  `}
`;

export default DropzoneContainer;
