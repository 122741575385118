import { Field } from 'formik';
import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import AutoTranslateToggle from '../AutoTranslateToggle';
import PrimaryButton from '../buttons/PrimaryButton';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import Modal from '../modal/Modal';
import SelectorWithLabel from '../SelectorWithLabel';
import TextAreaWithLabel from '../TextAreaWithLabel';
import TextFieldWithLabel from '../TextFieldWithLabel';
import Title from '../Title';

interface ResearchSettingsModalProps {
  onClose: VoidFunction;
  hidden?: boolean;
}

const ResearchSettingsModal: React.FC<
  PropsWithChildren<ResearchSettingsModalProps>
> = ({ onClose, hidden }) => {
  const [tapCounter, setTapCounter] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);

  const onEasterEggTap = () => {
    if (tapCounter > 6) {
      return;
    } else if (tapCounter === 6) {
      if (isAdmin) {
        return;
      }
      setIsAdmin(true);
    } else if (tapCounter === 0) {
      setTimeout(() => {
        if (setTapCounter) {
          setTapCounter(0);
        }
      }, 3000);
      setTapCounter(1);
    } else {
      setTapCounter((curr) => curr + 1);
    }
  };

  return (
    <Modal onClose={onClose} hidden={hidden}>
      <Container>
        <HorizonalContainer>
          <VerticalContainer>
            <StyledTitle onClick={onEasterEggTap}>
              Additional settings
            </StyledTitle>
            <SelectorWithLabel
              label='Primary language'
              name='codebook_language'
              options={[
                { label: 'English', value: 'English' },
                { label: 'Hebrew', value: 'Hebrew' },
              ]}
            />
            <TextFieldWithLabel
              label='Max number of themes'
              name='number_of_themes'
              inputType='number'
            />
            <AutoTranslateToggle />
            {/* <Label>
              <Field type='checkbox' name='with_sentiment' />
              Sentiment Analysis
            </Label> */}
          </VerticalContainer>
          {isAdmin ? (
            <VerticalContainer>
              <StyledTitle>Admin settings</StyledTitle>
              <label>
                <Field type='checkbox' name='with_other_bucket' />
                With Other Bucket (should capture reviews that don't fit any
                theme)
              </label>
              <TextFieldWithLabel
                label='Interaction type'
                name='interaction_type'
              />
              <TextFieldWithLabel
                label='Interaction type plural'
                name='interaction_type_plural'
              />
              <TextFieldWithLabel
                label='Interaction source'
                name='interaction_source'
              />
              <TextFieldWithLabel
                label='Minimum characters in survey response (iclusive)'
                name='minimum_filtered_content_char_length'
                inputType='number'
              />
              <SelectorWithLabel
                name='mode'
                label='mode'
                options={[
                  { label: '5', value: '5' },
                  { label: '4', value: '4' },
                  { label: '3', value: '3' },
                  { label: '2', value: '2' },
                  { label: '1', value: '1' },
                ]}
              />
              {/* <SelectorWithLabel
                name='theme_gen_algorithm_id'
                label='algorithm'
                options={[
                  { label: 'default', value: '0' },
                  { label: 'refine algo', value: '1' },
                  { label: 'incremental addition', value: '2' },
                  { label: 'density of themes', value: '3' },
                  // { label: 'start big and unify', value: '4' },
                ]}
              /> */}
              <TextFieldWithLabel
                label='Sample size - For Theme Generation'
                name='sample_size'
                inputType='number'
              />
              <TextFieldWithLabel
                label='Minimium required count of related interactions'
                name='minimium_required_count_of_related_interactions'
                inputType='number'
              />
              <TextFieldWithLabel
                label={'Company Name (REQUIRED)'}
                name='company_name'
                placeholder='e.g. Chase bank'
              />
              <CompanyMetadata
                label={'Company Metadata - for prompt'}
                placeholder='e.g. "Chase the bank is issuing the Disney debit card"'
                name='company_metadata'
              />
            </VerticalContainer>
          ) : null}
        </HorizonalContainer>
        <PrimaryButton type='button' label='OK' onClick={onClose} />
      </Container>
    </Modal>
  );
};

export default ResearchSettingsModal;

const Container = styled(CenteredVerticalFlex)``;

const VerticalContainer = styled.div`
  width: 414px;
  display: flex;
  flex-direction: column;
  margin: 40px;
`;

const HorizonalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CompanyMetadata = styled(TextAreaWithLabel)`
  min-width: 500px;
  border: none;
  border-bottom: 1px solid rgb(133, 133, 133);
  margin-bottom: 16px;
`;
const StyledTitle = styled(Title)`
  margin-bottom: 36px;
`;
