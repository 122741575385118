import { PropsWithChildren } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import UploaderIcon from '../../assets/uploader.svg';
import { Colors } from '../../theme/Colors';
import TextButtonWithIcon from '../buttons/TextButtonWithIcon';
import HorizontalFlex from '../containers/HorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import DownloadIconCurlyEdges from '../icons/DownloadIconCurlyEdges';
import InfoIcon from '../icons/InfoIcon';
import P1 from '../newTextComponents/P1';
import P3 from '../newTextComponents/P3';
import TextWithIcon from '../newTextComponents/TextWithIcon';
import ContentfuTooltip from '../tooltips/ContentfulTooltip';
import CodebookFileSetupCard from './CodebookFileSetupCard';
import DropzoneContainer from './DropzoneContainer';

interface UploadCodebookFileInputWithDropzoneProps {
  onSelectFile: (acceptedFiles: File[]) => void;
}

const UploadCodebookFileInputWithDropzone: React.FC<
  PropsWithChildren<UploadCodebookFileInputWithDropzoneProps>
> = ({ onSelectFile }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onSelectFile,
  });

  return (
    <Container>
      <Dropzone {...getRootProps()} $isActive={isDragActive} $withHover>
        <input {...getInputProps()} />
        <UploadImage src={UploaderIcon} alt='Uploader icon' />
        <Title>Drag and drop here or click to upload a themes file.</Title>
        <Text>You may upload .xls, .xlsx, .csv files</Text>
      </Dropzone>
      <ButtonsContainer>
        <ContentfuTooltip
          tooltipContent={<CodebookFileSetupCard />}
          position='top'
        >
          <DataSetupInstructions
            text='How to Structure Your Themes File'
            icon={<StyledInfoIcon color={Colors.P100} />}
            variant='primary'
          />
        </ContentfuTooltip>
        <DownloadDataFileExampleButton
          text='Download themes file example'
          icon={<StyledDownloadIcon color={Colors.B50} />}
          onClick={() => {
            const link = document.createElement('a');
            link.href = '/examples/codebook-demo-file.xlsx';
            link.download = 'codebook-demo-file.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
          variant='bright'
        />
      </ButtonsContainer>
    </Container>
  );
};

export default UploadCodebookFileInputWithDropzone;

const Container = styled(VerticalFlex)`
  align-items: stretch;
`;

const Dropzone = styled(DropzoneContainer)`
  width: 460px;
  height: 220px;
`;

const ButtonsContainer = styled(HorizontalFlex)`
  justify-content: space-between;
  margin-top: 6px;
`;

const DownloadDataFileExampleButton = styled(TextButtonWithIcon)`
  padding-right: 0;
`;

const DataSetupInstructions = styled(TextWithIcon)`
  /* 0.6em is the padding of DownloadDataFileExampleButton */
  padding: 0.6em 0;
`;

const UploadImage = styled.img`
  width: 70px;
`;

const Title = styled(P1)`
  margin: 24px 0 8px 0;
`;

const Text = styled(P3)`
  color: ${Colors.B60};
`;

const StyledInfoIcon = styled(InfoIcon)`
  height: 16px;
  width: 16px;
`;

const StyledDownloadIcon = styled(DownloadIconCurlyEdges)`
  height: 16px;
  width: 16px;
`;
