import { useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  reportClientSeenUpdateVersionModal,
  reportOutdatedClientVersion,
} from '../api/pipelineApi';
import logger from '../utils/logger';
import { useUser } from './useUser';

const useUpdateClientVersion = () => {
  const [updateType, setUpdateType] = useState<null | 'critical' | 'normal'>(
    null
  );
  const [showDialog, setShowDialog] = useState(false);
  const [updatedVersion, setUpdatedVersion] = useState<string | null>(null);

  const user = useUser();
  const orgId = user?.organization_id;

  const { mutateAsync: reportOutdatedClientVersionMutateAsync } = useMutation<
    any,
    Error,
    { currentVersion: string; sessionDuration: number; orgId: number }
  >({
    mutationFn: (data: {
      currentVersion: string;
      sessionDuration: number;
      orgId: number;
    }) =>
      reportOutdatedClientVersion(
        data.currentVersion,
        data.sessionDuration,
        data.orgId
      ),
  });

  const { mutateAsync: reportClientSeenUpdateVersionModalMutateAsync } =
    useMutation<
      any,
      Error,
      { currentVersion: string; sessionDuration: number; orgId: number }
    >({
      mutationFn: (data: {
        currentVersion: string;
        sessionDuration: number;
        orgId: number;
      }) =>
        reportClientSeenUpdateVersionModal(
          data.currentVersion,
          data.sessionDuration,
          data.orgId
        ),
    });

  const checkHasSeenUpdate = (newVersion: string) => {
    const seenVersion = sessionStorage.getItem('seenVersion');
    return seenVersion === newVersion;
  };

  const checkVersion = async (currentVersion: string) => {
    const [currentMajor, currentMinor] = currentVersion.split('.').map(Number);

    if (currentMajor === undefined || currentMinor === undefined) {
      logger.error(
        'checkVersion - currentVersion is not a valid version ',
        currentVersion
      );
      return;
    }

    logger.info('checkVersion - start with currentVersion: ', currentVersion);
    try {
      const randomDelay = Math.random() * 10000;
      await new Promise((resolve) => setTimeout(resolve, randomDelay));

      const response = await fetch(`/version.json?t=${new Date().getTime()}`);
      if (!response.ok) {
        logger.error(
          'useUpdateClientVersion - Failed to fetch version: ',
          response.statusText
        );
        return;
      }

      const data = await response.json();
      const newVersion = data?.version;

      if (!newVersion) {
        logger.error('checkVersion - no version in response data.version');
        return;
      }

      setUpdatedVersion(newVersion);
      if (checkHasSeenUpdate(newVersion)) {
        logger.info('checkVersion - seen update, no need to check');
        return;
      }

      const [newMajor, newMinor] = newVersion.split('.').map(Number);
      logger.info('useUpdateClientVersion - remote version: ', newVersion);
      const sessionDuration =
        Date.now() -
        parseInt(
          sessionStorage.getItem('sessionStart') || Date.now().toString()
        );

      if (newMajor > currentMajor) {
        logger.info('useUpdateClientVersion - major version update');
        setUpdateType('critical');
        setShowDialog(true);
        await reportClientSeenUpdateVersionModalMutateAsync({
          currentVersion,
          sessionDuration,
          orgId: orgId!,
        });
      } else if (newMinor > currentMinor) {
        logger.info('useUpdateClientVersion - minor version update');
        await reportOutdatedClientVersionMutateAsync({
          currentVersion,
          sessionDuration,
          orgId: orgId!,
        });
        setUpdateType('normal');
        sessionStorage.setItem('seenVersion', newVersion);
      }
    } catch (error) {
      logger.error('useUpdateClientVersion - Failed to check version:', error);
    }
  };

  useEffect(() => {
    // Version checking logic same as before
    if (showDialog) {
      logger.info('useEffect - showDialog is true');
      return;
    }

    const currentVersion = document
      .querySelector('meta[name="app-version"]')
      ?.getAttribute('content');

    if (!currentVersion) {
      logger.error('No app version found');
      return;
    }

    if (!sessionStorage.getItem('sessionStart')) {
      sessionStorage.setItem('sessionStart', Date.now().toString());
    }

    const interval = setInterval(checkVersion, 0.3 * 60 * 1000, currentVersion);

    return () => clearInterval(interval);
  }, [orgId, showDialog]);

  const handleRefresh = () => {
    sessionStorage.setItem('seenVersion', updatedVersion!);
    window.location.reload();
  };

  const handleDismiss = () => {
    sessionStorage.setItem('seenVersion', updatedVersion!);
    setUpdateType(null);
    setShowDialog(false);
  };

  return { showDialog, updateType, handleRefresh, handleDismiss };
};

export default useUpdateClientVersion;
