import { CSSProperties, PropsWithChildren, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { TooltipPosition, getTooltipPositionStyles } from '../../utils/utils';

interface ContentfuTooltipProps {
  position?: TooltipPosition;
  tooltipContent: React.ReactNode;
  disabled?: boolean;
  style?: CSSProperties;
}

const ContentfuTooltip: React.FC<PropsWithChildren<ContentfuTooltipProps>> = ({
  position = 'top',
  tooltipContent,
  disabled,
  style,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const elementRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const show = () => {
    if (disabled) return;
    setShowTooltip(true);
  };

  const hide = () => {
    setShowTooltip(false);
  };

  const tooltipWidth = 320;

  return (
    <Container ref={elementRef} onMouseEnter={show} onMouseLeave={hide}>
      {children}
      {elementRef.current && (
        <ToolTipElement
          ref={tooltipRef}
          $show={showTooltip}
          $position={position}
          style={{
            position: 'absolute',
            zIndex: 1000,
            width: tooltipWidth,
            ...getTooltipPositionStyles(position),
            ...style,
          }}
        >
          {tooltipContent}
        </ToolTipElement>
      )}
    </Container>
  );
};

export default ContentfuTooltip;

const Container = styled.div`
  position: relative;
`;

const ToolTipElement = styled.div<{
  $show?: boolean;
  $position: TooltipPosition;
}>`
  /* max-width: 280px; */
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px #0000001a;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s, visibility 0.4s;
  ${({ $show }) => ($show ? 'opacity: 1; visibility: visible;' : null)}

  &:before {
    content: ' ';
    position: absolute;
    height: 0;
    width: 0;

    ${({ $position }) => {
      switch ($position) {
        case 'bottom':
          return css`
            border-left: solid transparent 10px;
            border-right: solid transparent 10px;
            border-bottom: solid white 10px;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
          `;
        case 'left':
          return css`
            border-top: solid transparent 10px;
            border-bottom: solid transparent 10px;
            border-left: solid white 10px;
            right: -10px;
            top: 50%;
            transform: translateY(-50%);
          `;
        case 'right':
          return css`
            border-top: solid transparent 10px;
            border-bottom: solid transparent 10px;
            border-right: solid white 10px;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
          `;
        default: // top
          return css`
            border-left: solid transparent 10px;
            border-right: solid transparent 10px;
            border-top: solid white 10px;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
          `;
      }
    }}
  }
`;
