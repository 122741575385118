import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  fetchProjectApi,
  getColumnDefinitionsApi,
  getJobsDisplayApi,
  setupProjectApi,
} from '../api/pipelineApi';
import { Colors } from '../theme/Colors';
import { ColumnDefinitionDraft, JobDisplayData } from '../types';
import { GENERAL_ERROR_MESSAGE } from '../utils/constants';
import logger from '../utils/logger';
import { imagesArray } from '../utils/utils';
import PageHeader from './PageHeader';
import BackButtonWithActive from './buttons/BackButtonWithActive';
import PrimaryButton from './buttons/PrimaryButton';
import StyledBackIcon from './buttons/StyledBackIcon';
import HorizontalFlex from './containers/HorizontalFlex';
import PageContainer from './containers/PageContainer';
import PageHeadersContainer from './containers/PageHeadersContainer';
import VerticalFlex from './containers/VerticalFlex';
import ErrorModal from './modal/ErrorModal';
import H1 from './newTextComponents/H1';
import H2 from './newTextComponents/H2';
import ColumnMapper from './project/ColumnMapper';
import ProjectImage from './project/ProjectImage';
import UploadDataFile from './project/UploadDataFile';
import LoadingModal from './tagResults/LoadingModal';

interface CreateProjectPageProps {}

const CreateProjectPage: React.FC<
  PropsWithChildren<CreateProjectPageProps>
> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { projectId } = useParams();

  if (!projectId) {
    throw new Error('projectId is required');
  }

  const [columnDefs, setColumnDefs] = useState<
    ColumnDefinitionDraft[] | undefined
  >();
  const [formStep, setFormStep] = useState<1 | 2>(1);

  const {
    data: projectData,
    isPending: projectIsPending,
    isError: projectIsError,
    error: projectError,
    refetch: projectRefetch,
  } = useQuery<any, Error>({
    queryKey: ['project', projectId],
    queryFn: () => fetchProjectApi(projectId),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  const calledOnceRef = useRef(false);
  useEffect(() => {
    if (!projectData || calledOnceRef.current) return;
    calledOnceRef.current = true;

    if (projectData.input_file_name) {
      setFormStep(2);
      return;
    }
  }, [projectData]);

  const goBack = () => {
    if (formStep !== 2) {
      logger.error('unexpected scenario, trying to go back from formStep');
      return;
    }

    //@ts-ignore
    setFormStep((prev) => prev - 1);
  };

  const {
    data: columnDefinitionsData,
    isPending: columnDefinitionsIsPending,
    error: columnDefinitionsError,
    isError: columnDefinitionsIsError,
    refetch: columnDefinitionsRefetch,
  } = useQuery<any, Error>({
    queryKey: ['columnDefinitions', projectId],
    queryFn: () => getColumnDefinitionsApi(projectId),
    retry: 3,
    enabled: !!projectData?.input_file_name,
    refetchOnWindowFocus: false,
  });

  const {
    data: jobsDisplayData,
    isPending: jobsDisplayLoading,
    // isError: jobsDisplayIsError,
    // refetch: jobsDisplayRefetch,
  } = useQuery<any, Error, JobDisplayData[]>({
    queryKey: ['jobsDisplay', projectId],
    queryFn: () => getJobsDisplayApi(projectId),
    retry: 5,
    refetchOnWindowFocus: false,
    // refetchInterval: isTabVisible ? 5000 : false,
  });

  useEffect(() => {
    if (!columnDefinitionsData) return;

    setColumnDefs(columnDefinitionsData);
  }, [columnDefinitionsData]);

  const {
    mutateAsync: setupProjectMutateAsync,
    isPending: setupProjectIsPending,
    error: setupProjectError,
    reset: setupProjectReset,
  } = useMutation<any, Error, ColumnDefinitionDraft[]>({
    mutationFn: (data) => setupProjectApi(data, projectId),
    onSuccess() {
      queryClient.resetQueries({
        queryKey: ['columnDefinitions', projectId],
      });
      queryClient.resetQueries({ queryKey: ['project', projectId] });
    },
  });

  const onDataFileProcessComplete = async () => {
    queryClient.invalidateQueries({
      queryKey: ['columnDefinitions', projectId],
    });
    queryClient.invalidateQueries({ queryKey: ['project', projectId] });

    setFormStep(2);
  };

  const onColumnMappingComplete = async () => {
    if (!columnDefs) {
      logger.error('unexpected scenario, columnDefs is undefined');
      return;
    }

    await setupProjectMutateAsync(columnDefs);
    navigate(`/projects/${projectId}`);
  };

  const projectSetupRelatedIsPending =
    setupProjectIsPending || columnDefinitionsIsPending;

  const renderCommonModals = () => {
    return (
      <>
        <LoadingModal
          title='Setting up project...'
          hidden={!setupProjectIsPending}
        />
        <ErrorModal
          show={!!setupProjectError}
          onClose={setupProjectReset}
          title={'Update Column Definitions Failed'}
          subTitle={setupProjectError?.message || GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={projectIsError}
          onClose={projectRefetch}
          title={'Loading Project Configuration Failed'}
          subTitle={projectError?.message || GENERAL_ERROR_MESSAGE}
        />
      </>
    );
  };

  const isLoading = projectIsPending && !projectError;
  if (isLoading) {
    return (
      <LoadingModal
        title='Loading Project Configuration...'
        hidden={!isLoading}
      />
    );
  }

  if (formStep === 1) {
    return (
      <PageContainer>
        <PageHeader />
        <PageHeadersContainer>
          <BackButtonWithActive
            icon={<StyledBackIcon />}
            onClick={() => navigate('/projects')}
          />
          <H1>Upload your survey data</H1>
        </PageHeadersContainer>
        <ContentsContainer>
          <UploadDataFile
            projectId={projectId}
            isFileUploaded={projectData?.input_file_name?.length > 0}
            onDataFileProcessComplete={onDataFileProcessComplete}
            onClickNext={() => setFormStep(2)}
          />
        </ContentsContainer>
        {renderCommonModals()}
      </PageContainer>
    );
  }

  const selectedOEsCount = columnDefs?.filter(
    (c) => c.columnType === 'open_ended'
  )?.length;

  const selectedContentfulOEsCount = columnDefs?.filter(
    (c) => c.columnType === 'open_ended' && c.rowCount !== 0
  )?.length;

  const isGoBackToFileUploadDisabled =
    jobsDisplayData && jobsDisplayData?.length > 0;

  return (
    <PageContainer>
      <PageHeader />
      <ProjectImage src={imagesArray[projectData.id % imagesArray.length]} />
      <PageHeadersContainer>
        {!isGoBackToFileUploadDisabled && (
          <BackButtonWithActive icon={<StyledBackIcon />} onClick={goBack} />
        )}

        <H2>Select the open ended questions to be analysed</H2>
      </PageHeadersContainer>
      <ContentsContainer>
        <StyledColumnMapper
          jobsDisplay={jobsDisplayData}
          columnDefs={columnDefs}
          setColumnDefs={setColumnDefs}
        />

        <LoadingModal
          title='Setting up project...'
          hidden={!projectSetupRelatedIsPending}
        />
        <LoadingModal
          title='Setting up project...'
          hidden={!jobsDisplayLoading}
        />
        {renderCommonModals()}
        <ErrorModal
          show={!!columnDefinitionsIsError}
          onClose={columnDefinitionsRefetch}
          title={'Error with project configuration'}
          subTitle={columnDefinitionsError?.message || GENERAL_ERROR_MESSAGE}
        />
      </ContentsContainer>
      <StickyBottomContainer>
        <BigButtonsContainer>
          <PrimaryButton
            label={`Import ${selectedOEsCount} Questions`}
            onClick={onColumnMappingComplete}
            disabled={
              !columnDefs ||
              columnDefs.length === 0 ||
              selectedContentfulOEsCount === 0
            }
          />
        </BigButtonsContainer>
      </StickyBottomContainer>
    </PageContainer>
  );
};

export default CreateProjectPage;

const ContentsContainer = styled(VerticalFlex)`
  margin-top: 120px;
  flex: 1;
`;

const StyledColumnMapper = styled(ColumnMapper)`
  flex: 1;
`;

const StickyBottomContainer = styled(HorizontalFlex)`
  justify-content: center;
  align-self: stretch;
  position: sticky;
  bottom: 0;
  border-top: 1px solid ${Colors.B20};
  background-color: ${Colors.WHITE};
`;

const BigButtonsContainer = styled(HorizontalFlex)`
  justify-content: flex-end;
  padding: 12px 0;
  width: 1170px;
  /* background-color: ${Colors.WHITE}; */
`;
