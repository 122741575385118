import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import StyledButtonWithActive from './StyledButtonWithActive';

const BackButtonWithActive = styled(StyledButtonWithActive)`
  padding: 0 16px 16px 0;
  justify-content: flex-start;
  > svg:hover {
    background-color: ${Colors.P10};
  }
  > svg {
    border: 1px solid ${Colors.P100};
  }
  &:active > svg path {
    fill: ${Colors.WHITE};
  }
`;

export default BackButtonWithActive;
