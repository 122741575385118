import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { getJobVersionsForJobApi } from '../../../api/pipelineApi';
import RestoreVersionIcon from '../../../assets/restore_version.svg';
import { JobVersionData } from '../../../types';
import { GENERAL_ERROR_MESSAGE } from '../../../utils/constants';
import JobVersionMenuItem from '../../dashboard/JobVersionMenuItem';
import MenuItemIcon from '../../menu/MenuItemIcon';
import MenuButton from '../../menu/MenuItemTextButtonWithIcon';
import ErrorModal from '../../modal/ErrorModal';
import LoadingModal from '../../tagResults/LoadingModal';
import useRestoreVersion from './useRestoreVersion';

interface RestoreVersionMenuProps {
  projectId: string;
  jobId: string;
  activeJobVersionId: string;
  setShowMenu: (showMenu: boolean) => void;
}

const RESTORE_VERSION_DRAWER_MENU_WIDTH = 222;
const RESTORE_VERSION_DRAWER_MENU_PADDING = 16;
const RestoreVersionMenu: React.FC<RestoreVersionMenuProps> = ({
  projectId,
  jobId,
  activeJobVersionId,
  setShowMenu,
}) => {
  const { data: jobVersionsData } = useQuery<JobVersionData[], Error>({
    queryKey: ['versionsDataForJob', jobId, activeJobVersionId],
    queryFn: () => getJobVersionsForJobApi(jobId!),
    refetchOnWindowFocus: false,
  });

  const [menuPosition, setMenuPosition] = useState<'left' | 'right'>('right');
  const menuButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOverflow = () => {
      if (menuButtonRef.current) {
        const { right } = menuButtonRef.current.getBoundingClientRect();

        if (
          right +
            RESTORE_VERSION_DRAWER_MENU_WIDTH +
            RESTORE_VERSION_DRAWER_MENU_PADDING >
          window.innerWidth
        ) {
          setMenuPosition('left');
        } else {
          setMenuPosition('right');
        }
      }
    };

    handleOverflow();
    window.addEventListener('resize', handleOverflow);
    return () => {
      window.removeEventListener('resize', handleOverflow);
    };
  }, []);

  const {
    restoreVersion,
    restoreVersionIsLoading,
    restoreVersionError,
    restoreVersionReset,
  } = useRestoreVersion(projectId, jobId, activeJobVersionId, setShowMenu);

  return (
    <>
      <div ref={menuButtonRef} />
      <MenuButton
        text='Restore Version'
        icon={<MenuItemIcon src={RestoreVersionIcon} />}
        onClick={() => {}}
        withRightArrow
        menuDrawerPosition={menuPosition}
      >
        {jobVersionsData
          ?.filter((v) => v.status !== 'Failed')
          .map((version) => (
            <JobVersionMenuItem
              key={version.id}
              jobVersion={version}
              onClick={() => restoreVersion(version)}
            />
          ))}
      </MenuButton>
      <LoadingModal
        title='Restoring version...'
        hidden={!restoreVersionIsLoading}
      />
      <ErrorModal
        show={!!restoreVersionError}
        onClose={restoreVersionReset}
        title='Unexpected Error'
        subTitle={restoreVersionError?.message ?? GENERAL_ERROR_MESSAGE}
      />
    </>
  );
};

export default RestoreVersionMenu;
