import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import {
  createOrUpdateJobConfigurationApi,
  getJobApi,
  getJobConfigurationApi,
  translateUserContentsApi,
} from '../api/pipelineApi';
import { JobData } from '../types';
import logger from '../utils/logger';

const useTranslation = (jobVersionId: string, jobId: string) => {
  const [translateToggleChecked, setTranslateToggleChecked] = useState(false);
  const [presentTranslation, setPresentTranslation] = useState(false);
  const [translationIsLoading, setTranslationIsLoading] = useState(false);
  const [translationError, setTranslationError] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: createOrUpdateConfigMutateAsync } = useMutation<
    any,
    Error,
    object
  >({
    mutationFn: (data) =>
      createOrUpdateJobConfigurationApi(data, jobVersionId!),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['jobConfiguration', jobVersionId],
      });
    },
  });

  const { data: jobConfigurationData } = useQuery<any, Error>({
    queryKey: ['jobConfiguration', jobVersionId],
    queryFn: () => getJobConfigurationApi(jobVersionId!),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: jobData } = useQuery<JobData, Error>({
    queryKey: ['job', jobId],
    queryFn: () => getJobApi(jobId!),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  const {
    mutateAsync: translateUserContentsMutateAsync,
    isPending: translateUserContentsIsLoading,
  } = useMutation<any, Error>({
    mutationFn: () => translateUserContentsApi(jobVersionId!),
    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: ['job', jobId],
      });
      await queryClient.resetQueries({
        queryKey: ['tag_page', jobVersionId],
      });
    },
    onError: () => {
      setTranslationError(true);
    },
  });

  const resetTranslationError = () => {
    setTranslationIsLoading(false);
    setTranslateToggleChecked(false);
    setTranslationError(false);
  };

  const runOnceRef = useRef(false);

  useEffect(() => {
    if (jobData?.translations_status && !runOnceRef.current) {
      logger.info(
        'useTranslation - useEffect - called with translations_status: ',
        jobData.translations_status
      );

      runOnceRef.current = true;
      const codebook_language = jobConfigurationData?.codebook_language;
      logger.info(
        `update translateToggleChecked with translations_status: ${JSON.stringify(
          jobData.translations_status
        )} and codebook_language: ${codebook_language}`
      );
      const status = jobData.translations_status[codebook_language];
      logger.info(`update translateToggleChecked status: ${status}`);
      if (status === 'completed' && jobConfigurationData?.translate_contents) {
        setTranslateToggleChecked(true);
        setTranslationIsLoading(false);
        if (jobConfigurationData?.translate_contents) {
          setPresentTranslation(true);
        }
      } else if (status === 'in_progress') {
        setTranslationIsLoading(true);
      } else if (status === 'failed') {
        setTranslationIsLoading(false);
        setTranslateToggleChecked(false);
      }
    }
  }, [jobData, jobConfigurationData]);

  const toggleTranslate = async () => {
    logger.info('toggleTranslate - Start');
    if (translateUserContentsIsLoading) {
      logger.info(
        'toggleTranslate - translateUserContentsIsLoading is true, skipping'
      );
      return;
    }

    setTranslationIsLoading(true);

    await createOrUpdateConfigMutateAsync({
      translate_contents: !translateToggleChecked,
    });
    logger.info('toggleTranslate - updated config');

    if (!translateToggleChecked) {
      // turn on
      const codebook_language = jobConfigurationData?.codebook_language;
      const codebook_language_translation_status =
        jobData?.translations_status?.[codebook_language];

      if (codebook_language_translation_status === 'completed') {
        logger.info(
          'codebook_language_translation_status is already completed, skipping'
        );
        setTranslateToggleChecked(true);
        setTranslationIsLoading(false);
        setPresentTranslation(true);
      } else if (codebook_language_translation_status === 'in_progress') {
        logger.info(
          'codebook_language_translation_status is in progress, skipping'
        );
        setTranslationIsLoading(true);
        setPresentTranslation(false);
      } else {
        logger.info(
          `codebook_language_translation_status is ${codebook_language_translation_status}, triggering translation`
        );

        setTranslateToggleChecked(true);
        await translateUserContentsMutateAsync();
        setTranslationIsLoading(false);
        setPresentTranslation(true);
      }
    } else {
      // turn off
      setTranslateToggleChecked(false);
      setPresentTranslation(false);
      setTranslationIsLoading(false);
    }
  };

  return {
    toggleTranslate,
    resetTranslationError,
    presentTranslation,
    translateToggleChecked,
    translationIsLoading,
    translationError,
  };
};

export default useTranslation;
