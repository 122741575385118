import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import ButtonWithIcon from './ButtonWithIcon';

const StyledButtonWithActive = styled(ButtonWithIcon)`
  > svg:hover {
    background-color: ${Colors.B10};
  }
  &:active > svg {
    background-color: ${Colors.P100};
  }
`;

export default StyledButtonWithActive;
