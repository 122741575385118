import React from 'react';

interface DownloadIconProps {
  className?: string;
  color?: string;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({
  className,
  color = 'black',
}) => {
  return (
    <svg
      className={className}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 23.9995H22'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 8V20M16 20L19.5 16.5M16 20L12.5 16.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DownloadIcon;
