import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { FontFamily } from '../../theme/FontFamily';
import PrimaryButton from '../buttons/PrimaryButton';
import ButtonsContainer from '../containers/ButtonsContainer';
import Modal from './Modal';

interface MessageModalProps {
  show: boolean;
  onClose?: VoidFunction;
  onApprove?: VoidFunction;
  approveButtonText?: string;
  cancelButtonText?: string;
  title: string;
  subTitle: string;
  withCloseButton?: boolean;
}

const MessageModal: React.FC<MessageModalProps> = ({
  show,
  onClose,
  onApprove,
  approveButtonText,
  cancelButtonText,
  title,
  subTitle,
  withCloseButton = true,
}) => {
  return show ? (
    <Modal onClose={onClose} withCloseButton={withCloseButton}>
      <Container>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <ButtonsContainer>
          {cancelButtonText ? (
            <PrimaryButton
              type='button'
              label={cancelButtonText}
              onClick={onClose}
              withClearBackground
            />
          ) : null}
          {onApprove && approveButtonText ? (
            <PrimaryButton
              type='button'
              label={approveButtonText}
              onClick={onApprove}
            />
          ) : null}
        </ButtonsContainer>
      </Container>
    </Modal>
  ) : null;
};

export default MessageModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const Title = styled.div`
  font-family: ${FontFamily.ROBOTO};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.GRAY_100};
  margin-bottom: 16px;
  max-width: 300px;
  text-align: center;
`;

const SubTitle = styled(Title)`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.PRIMARY};
  margin-bottom: 24px;
`;
