import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import UploadCodebookFileInputWithDropzone from '../file-uploader/UploadCodebookFileInputWithDropzone';
import Modal from '../modal/Modal';
import H2 from '../newTextComponents/H2';

interface LoadThemesModalProps {
  onClose: VoidFunction;
  hidden: boolean;
  onSelectFile: (acceptedFiles: File[]) => void;
}

const LoadThemesModal: React.FC<PropsWithChildren<LoadThemesModalProps>> = ({
  onClose,
  hidden,
  onSelectFile,
}) => {
  return (
    <StyledModal onClose={onClose} hidden={hidden}>
      <OuterContainer>
        <Title>Load Themes</Title>
        <UploadCodebookFileInputWithDropzone onSelectFile={onSelectFile} />
      </OuterContainer>
    </StyledModal>
  );
};

export default LoadThemesModal;

const StyledModal = styled(Modal)`
  width: 524px;
  height: 360px;
  padding: 0;
  border-radius: none;
`;

const OuterContainer = styled(CenteredVerticalFlex)`
  cursor: pointer;
  align-self: stretch;
  padding: 24px;
  border-radius: 12px;
`;

const Title = styled(H2)`
  align-self: flex-start;
  margin-bottom: 20px;
`;
