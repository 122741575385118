import { Field, FieldProps } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../theme/Colors';
import Label from './Label';
import ButtonWithIcon from './buttons/ButtonWithIcon';
import Tooltip from './buttons/Tooltip';
import CenteredHorizontalFlex from './containers/CenteredHorizontalFlex';
import InfoIcon from './icons/InfoIcon';

interface AutoTranslateToggleProps {}

const AutoTranslateToggle: React.FC<AutoTranslateToggleProps> = ({}) => {
  return (
    <Container>
      <CenteredHorizontalFlex>
        <StyledLabel>Translate Verbatim</StyledLabel>
        <Tooltip
          style={{
            position: 'absolute',
            right: 0,
            top: -146,
            left: -133,
            width: 325,
          }}
          toolTipText='Translate verbatim responses for display in the Blix UI. Final data will include both the original and translated verbatim.'
        >
          <InfoIconButton icon={<InfoIcon />} onClick={() => {}} />
        </Tooltip>
      </CenteredHorizontalFlex>
      <Field name='translate_contents'>
        {({ field }: FieldProps) => (
          <ToggleWrapper>
            <ToggleInput
              type='checkbox'
              {...field}
              checked={field.value === true}
              onChange={(e) =>
                field.onChange({
                  target: {
                    name: field.name,
                    value: e.target.checked,
                  },
                })
              }
              id={field.name}
            />
            <ToggleSlider />
          </ToggleWrapper>
        )}
      </Field>
    </Container>
  );
};

export default AutoTranslateToggle;

const Container = styled(CenteredHorizontalFlex)`
  margin-bottom: 16px;
  justify-content: space-between;
`;

const InfoIconButton = styled(ButtonWithIcon)`
  padding: 0;
  margin-left: 8px;
`;

const StyledLabel = styled(Label)`
  text-align: left;
  color: ${Colors.PRIMARY};
`;

const ToggleWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${Colors.P100};
  }

  &:focus + span {
    box-shadow: 0 0 1px ${Colors.P100};
  }

  &:checked + span:before {
    transform: translateX(16px);
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 16px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
