import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import P2 from '../newTextComponents/P2';
import TransparentButton from './TransparentButton';

interface TextButtonWithIconProps {
  text: string;
  icon: ReactElement;
  onClick: () => void;
  className?: string;
  variant?: 'primary' | 'secondary' | 'bright';
}

const TextButtonWithIcon: React.FC<TextButtonWithIconProps> = ({
  text,
  icon,
  onClick,
  className,
  variant = 'secondary',
}) => {
  return (
    <Button className={className} onClick={onClick} type='button'>
      {icon}
      <Label variant={variant}>{text}</Label>
    </Button>
  );
};

export default TextButtonWithIcon;

const Button = styled(TransparentButton)`
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const Label = styled(P2)<{ variant: 'primary' | 'secondary' | 'bright' }>`
  font-size: 13px;
  color: ${({ variant }) =>
    variant === 'primary'
      ? Colors.P100
      : variant === 'secondary'
      ? Colors.B70
      : Colors.B50};
`;
