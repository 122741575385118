import React from 'react';

interface DownloadIconCurlyEdgesProps {
  className?: string;
  color?: string;
}

const DownloadIconCurlyEdges: React.FC<DownloadIconCurlyEdgesProps> = ({
  className,
  color = 'black',
}) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.75 14C5.16421 14 5.5 14.3358 5.5 14.75V16.25C5.5 17.4927 6.50736 18.5 7.75 18.5H16.25C17.4927 18.5 18.5 17.4927 18.5 16.25V14.75C18.5 14.3358 18.8358 14 19.25 14C19.6642 14 20 14.3358 20 14.75V16.25C20 18.3211 18.3211 20 16.25 20H7.75C5.67894 20 4 18.3211 4 16.25V14.75C4 14.3358 4.33579 14 4.75 14Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12 4C12.4142 4 12.75 4.33579 12.75 4.75V14.25C12.75 14.6642 12.4142 15 12 15C11.5858 15 11.25 14.6642 11.25 14.25V4.75C11.25 4.33579 11.5858 4 12 4Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.23966 10.2004C8.5432 9.91856 9.01775 9.93613 9.2996 10.2397L12 13.1478L14.7004 10.2397C14.9823 9.93613 15.4568 9.91856 15.7603 10.2004C16.0639 10.4823 16.0815 10.9568 15.7996 11.2603L12.5496 14.7603C12.4077 14.9132 12.2086 15 12 15C11.7915 15 11.5923 14.9132 11.4504 14.7603L8.20041 11.2603C7.91856 10.9568 7.93613 10.4823 8.23966 10.2004Z'
        fill={color}
      />
    </svg>
  );
};

export default DownloadIconCurlyEdges;
